body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.RaDatagrid-headerCell {
  font-weight: bold !important;
}

.RaLogin-icon {
  display: none !important;
}

.RaLogin-card {
  z-index: 100;
  -webkit-animation: fade-in-move-down 0.7s;
  animation: fade-in-move-down 0.7s;
}

@media only screen and (max-width: 800px) {
  .Login-logo {
    display: none;
  }
}

@keyframes fade-in-move-down {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
